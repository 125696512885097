@import "../../../styles/colors.scss";
@import "../../../styles/constants.scss";

.evStatistics {
	width: 100%;
	height: 100%;

	.backgroundImageHolder {
		background-image: url(../../../public/assets/images/ev-stats-background.jpg);
		background-size: cover;
		background-position: center;

		width: 100%;
		height: 68.5%;
	}

	.greyPart {
		width: 100%;
		height: 100%;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: space-between;

		background-color: rgba($color: $black-color, $alpha: 0.5);

		h1 {
			margin-top: 141px;
			color: $white-color;

			@media screen and (max-width: $small-screen) {
				margin-top: 80px;
			}
			@media screen and (max-width: 767px) {
				margin-top: 70px;
			}
			@media screen and (max-width: $mobile-screen) {
				margin-top: 60px;
			}
		}

		.dataHolder {
			height: 100%;
		}

		@media screen and (max-width: 767px) {
			height: unset;
		}
	}

	.dataHolder {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		min-width: 1300px;
		width: 71vw;
		max-width: 1428px;

		@media screen and (max-width: $small-screen) {
			min-width: 800px;
		}

		@media screen and (max-width: 925px) {
			min-width: 720px;
			width: 85vw;
		}

		@media screen and (max-width: 767px) {
			flex-direction: column;
			min-width: unset;
			width: 100%;

			margin-top: 50px;
			margin-bottom: 50px;
			row-gap: 50px;
		}

		@media screen and (max-width: $mobile-screen) {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	.whitePart {
		width: 100%;
		background-color: $white-color;

		height: 31.5%;

		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 767px) {
			height: unset;
		}

		.kmNumber {
			h2,
			p {
				color: $black-color !important;
				font-weight: 500;
			}

			h2 {
				width: 505px;
				position: static;

				@media screen and (max-width: $small-screen) {
					width: 360px;
				}
				@media screen and (max-width: $mobile-screen) {
					width: 292px;
				}
			}
			p {
				margin-top: 0;
			}
		}

		.kmNumberBoi {
			h2,
			p {
				color: $black-color !important;
				font-weight: 500;
			}

			h2 {
				width: 505px;
				position: static;

				@media screen and (max-width: $small-screen) {
					width: 360px;
				}
				@media screen and (max-width: $mobile-screen) {
					width: 292px;
				}
			}
			p {
				margin-top: 0;
			}
		}

		.measureButton {
			position: relative;
			left: 35px;

			@media screen and (max-width: $small-screen) {
				left: 60px;
			}

			@media screen and (max-width: 925px) {
				position: static;
			}

			@media screen and (max-width: 767px) {
				width: 300px;
			}
		}
	}
}
